export default {
  bold: "Latinka ExtraBold",
  extraLight: "Latinka ExtraLight",
  light: "Latinka Light",
  medium: "Latinka Medium",
  semiBold: "Latinka SemiBold",
  thin: "Latinka Thin",
  extraBold: "extraBold",
  lightItalicDemo: "lightItalicDemo",
  extraBoldDemo: "extraBoldDemo",
  blackLight: "BlackLabel Light",
  kBlack: "kBlack",
  kBlackItalic: "kBlackItalic",
  kBold: "kBold",
  kBoldItalic: "kBoldItalic",
  kExtraBold: "kExtraBold",
  kExtraBoldItalic: "kExtraBoldItalic",
  kHairline: "kHairline",
  kItalic: "kItalic",
  kLight: "kLight",
  kLightItalic: "kLightItalic",
  kMedium: "kMedium",
  kMediumItalic: "kMediumItalic",
  kRegular: "kRegular",
  kSemiBold: "kSemiBold",
  kSemiBoldItalic: "kSemiBoldItalic",
  kThin: "kThin",
  kThinItalic: "kThinItalic",
};
