export default {
  coral: "#fc665c",
  blue: "#1a01ba",
  black: "#000",
  white: "#fff",
  grey: "#d7d7d7",
  transparent: "transparent",
};

// orange-bordered | orange | blue | black | white
