import JobOffer1 from "../../images/jobOffer1.svg";
import JobOfferTitle1 from "../../images/jobOfferTitle1.svg";
import videographer from "./videographer";
import socialMediaSpecialist from "./socialMediaSpecialist";
import emailMarketingSpecialist from "./emailMarketingSpecialist";
import eCommerceMarketingSpecialist from "./eCommerceMarketingSpecialist";
import graphicDesigner from "./graphicDesigner";
import creativeCopywriter from "./creativeCopywriter";
import creativeGrowthSpecialist from "./creativeGrowthSpecialist";
import corporatePartnershipAndSponsorshipManager from "./corporatePartnershipAndSponsorshipManager";
import startupAdvisor from "./startupAdvisor";
import managingDirectorOfEvents from "./managingDirectorOfEvents";
import managingDirectorOfPrograms from "./managingDirectorOfPrograms";
import productManager from "./productManager";

export default {
  colorBlockOptions: {
    textWidth: "45vw",
    backgroundColor: "#000",
    type: "thin",
    size: 75,
    mobSize: 55,
  },
  descriptionOptions: {
    withoutMargin: true,
  },
  videographer,
  "social-media-specialist": socialMediaSpecialist,
  "email-marketing-specialist": emailMarketingSpecialist,
  "ecommerce-marketing-specialist": eCommerceMarketingSpecialist,
  "graphic-designer": graphicDesigner,
  "startup-advisor": startupAdvisor,
  "creative-copywriter": creativeCopywriter,
  "product-manager": productManager,
  "creative-growth-specialist": creativeGrowthSpecialist,
  "managing-director-of-events": managingDirectorOfEvents,
  "managing-director-of-programs": managingDirectorOfPrograms,
  "corporate-partnership-and-sponsorship-manager": corporatePartnershipAndSponsorshipManager,
  first: [
    {
      headerTitleWithImgTitle: "CAREER OPPORTUNITIES",
      headerTitleWithImgSubtitle: "This is a job post title",
      withHeaderTitleWithImg: true,
      headerTitleWithImgURL: JobOffer1,
    },
    {
      title: "POSITION SUMMARY",
    },
    {
      subtitle:
        "We elevate your brand’s presence with custom strategy, channel expertise & flawless execution.",
    },
    {
      description: [
        {
          title:
            "Consumers move seamlessly between platforms and media. So, multi-disciplinary doesn’t even begin to cover it — The most effective branding and marketing achieves balance, a mix of strategy, analytics, and magic. Your brand is the most valuable business asset you own.\n" +
            "Which is why we like to keep our process simple and everything starts with 'Why?'. We increase awareness, generate leads.\n" +
            "\n" +
            "By taking care of our clients, we take their reality into account and help them achieve their business goals. We foster long-term relationships by sharing successes together, having open and genuine discussions, and maintaining frequent communication.\n" +
            "\n" +
            "Consistency is perhaps the biggest benchmark for building brand trust. Starting out with purpose, clarity and direction make this much easier to achieve.  We work the agreed direction into a resolved visual identity through an iterative process of feedback and response between designer and client.",
        },
        {
          title:
            "Consumers move seamlessly between platforms and media. So, multi-disciplinary doesn’t even begin to cover it — The most effective branding and marketing achieves balance, a mix of strategy, analytics, and magic. Your brand is the most valuable business asset you own.\n" +
            "Which is why we like to keep our process simple and everything starts with 'Why?'. We increase awareness, generate leads.\n" +
            "\n" +
            "By taking care of our clients, we take their reality into account and help them achieve their business goals. We foster long-term relationships by sharing successes together, having open and genuine discussions, and maintaining frequent communication.\n" +
            "\n" +
            "Consistency is perhaps the biggest benchmark for building brand trust. Starting out with purpose, clarity and direction make this much easier to achieve.  We work the agreed direction into a resolved visual identity through an iterative process of feedback and response between designer and client.",
        },
        {
          title:
            "Consumers move seamlessly between platforms and media. So, multi-disciplinary doesn’t even begin to cover it — The most effective branding and marketing achieves balance, a mix of strategy, analytics, and magic. Your brand is the most valuable business asset you own.\n" +
            "Which is why we like to keep our process simple and everything starts with 'Why?'. We increase awareness, generate leads.\n" +
            "\n" +
            "By taking care of our clients, we take their reality into account and help them achieve their business goals. We foster long-term relationships by sharing successes together, having open and genuine discussions, and maintaining frequent communication.\n" +
            "\n" +
            "Consistency is perhaps the biggest benchmark for building brand trust. Starting out with purpose, clarity and direction make this much easier to achieve.  We work the agreed direction into a resolved visual identity through an iterative process of feedback and response between designer and client.",
        },
      ],
    },
    {
      title: "DAY IN THE LIFE",
    },
    {
      description: [
        {
          title:
            "'You’ll harness your skills to mix social management with content creation to scale a variety of Instagram accounts.\\n' +\n" +
            "                'You’ll work directly with the founders of Moken, as well as in many cases a whole group of startup founders and superstars who are next up for changing the world.\\n' +\n" +
            "                'You’ll be responsible for managing the organic content distributed across Instagram accounts and platforms.\\n' +\n" +
            "                'You’ll work with marketing leaders to execute on content and advertising initiatives via Instagram.\\n' +\n" +
            "                'You’ll be responsible for creating consistent posts targeted at specific audiences and demographics throughout Instagram, IGTV, Reels, IG Stories.\\n' +\n" +
            "                'You’ll build and engage with Instagram communities while managing and maintaining post schedules across each Instagram segment.\\n' +\n" +
            "                'You’ll tweak strategic plans to best ensure success with strengths, company focus, and ‘thought-leadership’  focuses are aligned.\\n' +\n" +
            "                'You’ll develop contacts with brands, organizations, or individuals while determining strategies to ensure success for all parties. \\n' +\n" +
            "                'You’ll be required to stay informed about industry trends to determine the best content for each Instagram segment.\\n' +\n" +
            "                'You will gain valuable knowledge into how an entrepreneur, startup, and company with global reach operate on a day-to-day basis.'",
        },
      ],
    },
    {
      title: "MUST HAVE SKILLS",
    },
    {
      description: [
        {
          title:
            "You’ll harness your skills to mix social management with content creation to scale a variety of Instagram accounts.\n" +
            "You’ll work directly with the founders of Moken, as well as in many cases a whole group of startup founders and superstars who are next up for changing the world.\n" +
            "You’ll be responsible for managing the organic content distributed across Instagram accounts and platforms.\n" +
            "You’ll work with marketing leaders to execute on content and advertising initiatives via Instagram.\n" +
            "You’ll be responsible for creating consistent posts targeted at specific audiences and demographics throughout Instagram, IGTV, Reels, IG Stories.\n" +
            "You’ll build and engage with Instagram communities while managing and maintaining post schedules across each Instagram segment.\n" +
            "You’ll tweak strategic plans to best ensure success with strengths, company focus, and ‘thought-leadership’  focuses are aligned.\n" +
            "You’ll develop contacts with brands, organizations, or individuals while determining strategies to ensure success for all parties. \n" +
            "You’ll be required to stay informed about industry trends to determine the best content for each Instagram segment.\n" +
            "You will gain valuable knowledge into how an entrepreneur, startup, and company with global reach operate on a day-to-day basis.",
        },
      ],
    },
    {
      title: "CANDIDATE PERSONALITY",
    },
    {
      description: [
        {
          title:
            "'You’ll harness your skills to mix social management with content creation to scale a variety of Instagram accounts.\\n' +\n" +
            "                'You’ll work directly with the founders of Moken, as well as in many cases a whole group of startup founders and superstars who are next up for changing the world.\\n' +\n" +
            "                'You’ll be responsible for managing the organic content distributed across Instagram accounts and platforms.\\n' +\n" +
            "                'You’ll work with marketing leaders to execute on content and advertising initiatives via Instagram.\\n' +\n" +
            "                'You’ll be responsible for creating consistent posts targeted at specific audiences and demographics throughout Instagram, IGTV, Reels, IG Stories.\\n' +\n" +
            "                'You’ll build and engage with Instagram communities while managing and maintaining post schedules across each Instagram segment.\\n' +\n" +
            "                'You’ll tweak strategic plans to best ensure success with strengths, company focus, and ‘thought-leadership’  focuses are aligned.\\n' +\n" +
            "                'You’ll develop contacts with brands, organizations, or individuals while determining strategies to ensure success for all parties. \\n' +\n" +
            "                'You’ll be required to stay informed about industry trends to determine the best content for each Instagram segment.\\n' +\n" +
            "                'You will gain valuable knowledge into how an entrepreneur, startup, and company with global reach operate on a day-to-day basis.'",
        },
      ],
    },
    // {
    //     title: 'HOW TO APPLY'
    // },
    // {
    //     description: [
    //         {
    //             title: 'Not everyone fits into a pre-defined job description. Whether you’re a professional ostrich babysitter or an expert developer, we look for unconventional excellence. Tell us how you think you can contribute. \n' +
    //                 '\n' +
    //                 'Who knows, maybe you’re exactly what we’ve been looking for all along.'
    //         }
    //     ]
    // },
    // {
    //     submit: 'Submit your resume'
    // },

    {
      withTitleContainerWithImg: true,
      titleContainerWithImgTitle: "HOW TO APPLY",
      titleContainerWithImgDescription: [
        {
          title:
            "Not everyone fits into a pre-defined job description. Whether you’re a professional ostrich babysitter or an expert developer, we look for unconventional excellence. Tell us how you think you can contribute.",
        },
        {
          title:
            "Who knows, maybe you’re exactly what we’ve been looking for all along.",
        },
      ],
      titleContainerWithImgImageURL: JobOfferTitle1,
    },
    {
      colorBlock: "#000",
      colorTitle: "Not the right position? Check out our other opportunities.",
      colorSubtitle: "SEE OPPORTUNITIES",
    },
    {
      withForm: true,
    },
  ],
};
