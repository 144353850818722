export default {
  lists: [
    {
      title: "PERKS OF BEING A PARTNER",
      list: [
        [
          "— 10 Custom brand name ideas\n",
          "— 5 Revisions based off your feedback\n",
          "— Results in 72 hours\n",
        ],
        [
          "— 10 Custom brand name ideas\n",
          "— 5 Revisions based off your feedback\n",
          "— Results in 72 hours\n",
        ],
      ],
    },
    {
      title: "REQUIREMENTS",
      list: [
        [
          "— 10 Custom brand name ideas\n",
          "— 5 Revisions based off your feedback\n",
          "— Results in 72 hours\n",
        ],
        [
          "— 10 Custom brand name ideas\n",
          "— 5 Revisions based off your feedback\n",
          "— Results in 72 hours\n",
        ],
      ],
    },
  ],
  images: ["", "", "", "", ""],
};
