export default {
  "/": "Moken | Startups Hub",
  "/moken-website": "Moken | Startups Hub",
  "/product-marketing": "Moken | Product Marketing",
  "/advisors": "Moken | Advisors",
  "/mentorship": "Moken | Mentorship",
  "/faqs": "Moken | FAQS",
  "/start-up-smart": "Moken | Startupsmart packages",
  "/eco-partners": "Moken | Ecosystem partners",
  "/education-partner": "Moken | Education partner",
  "/community-partner": "Moken | Community partner",
  "/for-founders": "Moken | For founders",
  "/advertise-with-us": "Moken | Advertise with us",
  "/for-startup-programs": "Moken | For Startup Programs",
  "/for-startups": "Moken | For startups",
  "/for-students": "Moken | For students",
  "/for-investors": "Moken | For investors",
  "/iamafounder": "Moken | #IAmAFounder",
  "/mo-ken": "Moken | Mo + Ken",
  "/connect-with-us": "Moken | Connect with us",
  "/about-programs": "Moken | About programs",
  "/ideation-to-innovation": "Moken | Ideation to innovation",
  "/raising-right": "Moken | Raising right",
  "/future-founders": "Moken | Future founders",
  "/moken-labs": "Moken | Moken labs",
  "/courses": "Moken | Courses",
  "/our-vision": "Moken | Our Vision",
  "/partnership-programs": "Moken | Partnership-programs",
  "/about-moken": "Moken | About Moken",
  "/product-strategy": "Moken | Product strategy",
  "/host-an-event": "Moken | Host an event",
  "/people-leadership": "Moken | People leadship",
  "/community-development": "Moken | Community development",
  "/design-develop": "Moken | Design develop",
  "/market-research-validation": "Moken | Market research validation",
  "/product-design": "Moken | Product design",
  "/product-development": "Moken | Product development",
  "/program-driven-marketing": "Moken | Program driven marketing",
  "/strategy-advisory": "Moken | Strategy advisory",
  "/branding": "Moken | Branding",
  "/community": "Moken | Community",
  "/about-agency": "Moken | About agency",
  "/our-partners": "Moken | Our partners",
  "/product-testing-team": "Moken | Product testing team",
  "/start-up-smart-packages": "Moken | Startup smart packages",
  "/expertise": "Moken | Expertise",
  "/privacy": "Moken | Privacy",
  "/terms": "Moken | Terms",
  "/upcoming-events": "Moken | Upcoming events",
  "/portfolio": "Moken | Portfolio",
  "/careers": "Moken | Careers",
  "/opportunities": "Moken | Opportunities",
  "/other": "Moken | Other",
  "/community-manager": "Moken | Community-manager",
  "/copywriter": "Moken | Copywriter",
  "/designer": "Moken | Designer",
  "/email-marketing": "Moken | Email marketing",
  "/event-coordinator": "Moken | Event coordinator",
  "/illustrator-graphic": "Moken | Illustrator graphic",
  "/frontend-developer": "Moken | Frontend developer",
  "/partnership-manager": "Moken | Partnership manager",
  "/podcast-host": "Moken | Podcast host",
  "/photographer": "Moken | Photographer",
  "/videographer": "Moken | Videographer",
  "/lead-infrastructure-engineer": "Moken | Lead infrastructure engineer",
  "/job-offer": "Moken | Job offer",
  "/social-media-manager": "Moken | Social media manager",
  "/youtube-host": "Moken | Youtube host",
  "/backend-developer": "Moken | Backend developer",
  "/graphic-designer": "Moken | Graphic designer",
  "/menuscreen": "Moken | Menu",
  "/innovation-week": "Moken | Innovation committee",
};
