export default {
  interests: [
    {
      title: "Building a Startup",
      value: "",
      templateID: "template_noiljla",
      backgroundColor: "#FDCDC6",
      classNameHoverColor: "building-startup",
    },
    {
      title: "Branding",
      value: "",
      templateID: "template_noiljla",
      backgroundColor: "#FDCDC6",
      classNameHoverColor: "branding",
    },
    {
      title: "MokenLabs",
      value: "",
      templateID: "",
      backgroundColor: "#EFB95E",
      classNameHoverColor: "mokenlabs",
    },
    // {
    //   title: "Access Network",
    //   value: "",
    //   templateID: 'template_noiljla',
    //   backgroundColor: '#DEE2F2',
    //   classNameHoverColor: 'access-network',

    // },
    {
      title: "Career Opportunities",
      value: "",
      // templateID: 'template_noiljla',
      backgroundColor: "#DEE2F2",
      classNameHoverColor: "careers-opportunities",
    },
    {
      title: "Programs",
      value: "",
      backgroundColor: "#EFB95E",
      classNameHoverColor: "programs",
    },
    {
      title: "Creating an App",
      value: "",
      backgroundColor: "#FDCDC6",
      classNameHoverColor: "creating-app",
    },
    {
      title: "Raising Capital",
      value: "",
      backgroundColor: "#FDCDC6",
      classNameHoverColor: "raising-capital",
    },
    {
      title: "Marketing & Growth",
      value: "",
      backgroundColor: "#FDCDC6",
      classNameHoverColor: "marketing-growth",
    },
    {
      title: "Mentorship Opportunities",
      value: "",
      backgroundColor: "#EFB95E",
      classNameHoverColor: "mentorship-opportunities",
    },
    {
      title: "InnovationWeek ",
      value: "",
      backgroundColor: "#FF9D72",
      classNameHoverColor: "innovation-week",
    },
    {
      title: "Partnering with Moken ",
      value: "",
      backgroundColor: "#DEE2F2",
      classNameHoverColor: "partnering-with-moken",
    },
    {
      title: "Events",
      value: "",
      backgroundColor: "#FDCDC6",
      classNameHoverColor: "events",
    },
    {
      title: "Investment Opportunities",
      value: "",
      backgroundColor: "#EFB95E",
      classNameHoverColor: "investment-opportunities",
    },
    {
      title: "Advisory & Consulting",
      value: "",
      backgroundColor: "#FDCDC6",
      classNameHoverColor: "advisory-consulting",
    },
    {
      title: "Something Else",
      value: "",
      backgroundColor: "#DEE2F2",
      classNameHoverColor: "something-else",
    },
  ],
};
