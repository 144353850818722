export default {
  weeks: [
    {
      title: "Benefit",
      subtitle: "Topic",
      description: "",
    },
    {
      title: "Benefit",
      subtitle: "Topic",
      description: "",
    },
    {
      title: "Benefit",
      subtitle: "Topic",
      description: "",
    },
    {
      title: "Benefit",
      subtitle: "Topic",
      description: "",
    },
  ],
  collaborates: [
    {
      title: "Moken Agency",
      subtitle:
        "Type Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut.",
    },
    {
      title: "Title goes here",
      subtitle:
        "Type Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut.",
    },
    {
      title: "Title goes here",
      subtitle:
        "Type Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut.",
    },
    {
      title: "Title goes here",
      subtitle:
        "Type Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut.",
    },
    {
      title: "Title goes here",
      subtitle:
        "Type Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut.",
    },
    {
      title: "Title goes here",
      subtitle:
        "Type Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut.",
    },
  ],
  learnMore: [
    {
      title: "Moken Partner Program Guide",
      link: "",
    },
    {
      title: "Ecosystem Partner Playbook",
      link: "",
    },
    {
      title: "Community Partner Playbook",
      link: "",
    },
    {
      title: "Startup Program Partner Playbook",
      link: "",
    },
  ],
};
