export default {
  helps: [
    {
      title: "Title goes here",
      subtitle:
        "Type Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut.",
    },
    {
      title: "Moken Agency",
      subtitle:
        "Type Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut.",
    },
  ],
  requirements: [
    {
      title: "Title goes here",
      subtitle:
        "Type Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut.",
    },
    {
      title: "Title goes here",
      subtitle:
        "Type Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut.",
    },
    {
      title: "Title goes here",
      subtitle:
        "Type Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut.",
    },
    {
      title: "Title goes here",
      subtitle:
        "Type Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut.",
    },
  ],
  something: [
    {
      title: "HOW WE HELP",
      subtitle: "Moken Agency",
      description:
        "Type Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut.",
    },
    {
      title: "REQUIREMENTS",
      subtitle: "Moken Agency",
      description:
        "Type Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt ut.",
    },
  ],
};
