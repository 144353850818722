import partners from "../../images/partners";
import {
  Kendra,
  Grace,
  Jessica,
  Yevgen,
  RomanLitvin,
  Adam,
  RomanBE,
  Hussain,
  Stas,
  Kostya,
  Oleksander,
  Nika,
  Vlad,
  Vitaly,
  Oleg,
  dogTwo,
  dogOne,
  Mohamed,
  Anastasia,
  Alex1,
  Erika,
  Pavel,
} from "../../images/people";

const {
  acadium,
  activate,
  ink,
  fast,
  hubspot,
  noissue,
  owner,
  segment,
  informa,
} = partners;

export default {
  peopleGroups: [
    {
      title: "MANAGEMENT TEAM",
      people: [
        {
          name: "Kendra Garagan",
          positions: ["MANAGING PARTNER", "MOKEN STARTUPS"],
          photo: Kendra,
        },
        // {
        //   name: "Mohamed Ibshara",
        //   positions: ["PROJECT MANAGER", "MOKEN AGENCY"],
        //   photo: Mohamed,
        // },
        {
          name: "Now Hiring!",
          positions: ["MANAGING DIRECTOR", "MOKEN EVENTS"],
          photo: dogOne,
        },
        {
          name: "Now Hiring!",
          positions: ["MANAGING DIRECTOR", "MOKEN PROGRAMS"],
          photo: dogTwo,
        },
      ],
    },
    {
      title: "PRODUCT TEAM",
      people: [
        {
          name: "Grace",
          positions: ["MARKETING", "MANAGER"],
          photo: Grace,
        },
        {
          name: "Jessica",
          positions: ["CORPORATE PARTNERSHIP", "MANAGER"],
          photo: Jessica,
        },
        {
          name: "Erika",
          positions: ["ACCESS USER", "EXPERIENCE DESIGNER"],
          photo: Erika,
        },
        {
          name: "Hussain",
          positions: ["GRAPHIC", "DESIGNER"],
          photo: Hussain,
        },
      ],
    },
    {
      title: "PRODUCT TEAM",
      people: [
        {
          name: "Pavel",
          positions: ["LEAD FRONT END", "DEVELOPER"],
          photo: Pavel,
        },
        {
          name: "Kostya",
          positions: ["SENIOR SYSTEMS", "ARCHITECT"],
          photo: Kostya,
        },
        {
          name: "Stanislav",
          positions: ["LEAD FRONT END", "DEVELOPER"],
          photo: Stas,
        },
        {
          name: "Vitaly",
          positions: ["LEAD USER EXPERIENCE", "DESIGNER"],
          photo: Vitaly,
        },
      ],
    },
    {
      title: "PRODUCT TEAM",
      people: [
        {
          name: "Adam",
          positions: ["LEAD USER EXPERIENCE", "DESIGNER"],
          photo: Adam,
        },
        {
          name: "Nika",
          positions: ["USER INTERFACE", "DESIGNER"],
          photo: Nika,
        },
        {
          name: "Oleg",
          positions: ["LEAD FRONT END", "DEVELOPER"],
          photo: Oleg,
        },
        {
          name: "Vlad",
          positions: ["LEAD BACK END", "DEVELOPER"],
          photo: Vlad,
        },
      ],
    },
    {
      title: "PRODUCT TEAM",
      people: [
        {
          name: "Alexander",
          positions: ["FRONT END", "DEVELOPER"],
          photo: Alex1,
        },
        {
          name: "Yevgen",
          positions: ["SENIOR BACKEND", "DEVELOPER"],
          photo: Yevgen,
        },
        {
          name: "Roman",
          positions: ["SENIOR FRONT END", "DEVELOPER"],
          photo: RomanLitvin,
        },
        {
          name: "Oleksander",
          positions: ["FRONT END", "DEVELOPER"],
          photo: Oleksander,
        },
      ],
    },
    {
      title: "PRODUCT TEAM",
      people: [
        {
          name: "Anastasia",
          positions: ["PHOTOGRAPHER"],
          photo: Anastasia,
        },
        {
          name: "Roman",
          positions: ["BACK END", "DEVELOPER"],
          photo: RomanBE,
        },
        {
          name: "",
          positions: [""],
          photo: "",
        },
        {
          name: "",
          positions: [""],
          photo: "",
        },
      ],
    },
  ],
  corporations: ["", "", "", "", "", "", "", "", ""],
  partners: [
    {
      width: 270,
      height: 86,
      image: ink,
    },
    {
      width: 348,
      height: 129,
      image: owner,
    },
    {
      width: 183,
      height: 73,
      image: fast,
    },
    {
      width: 329,
      height: 71,
      image: segment,
    },
    {
      width: 286,
      height: 137,
      image: hubspot,
    },
    {
      width: 338,
      height: 71,
      image: acadium,
    },
    {
      width: 311,
      height: 72,
      image: noissue,
    },
    {
      width: 299,
      height: 50,
      image: activate,
    },
    {
      width: 347,
      height: 107,
      image: informa,
    },
  ],
  peopleTest: [
    {
      name: "Kendra Garagan",
      positions: ["MANAGING PARTNER", "MOKEN STARTUPS"],
      photo: Kendra,
    },
    // {
    //   name: "Mohamed Ibshara",
    //   positions: ["PROJECT MANAGER", "MOKEN AGENCY"],
    //   photo: Mohamed,
    // },
    {
      name: "Now Hiring!",
      positions: ["MANAGING DIRECTOR", "MOKEN EVENTS"],
      photo: dogOne,
    },
    {
      name: "Now Hiring!",
      positions: ["MANAGING DIRECTOR", "MOKEN PROGRAMS"],
      photo: dogTwo,
    },
    {
      name: "Grace",
      positions: ["MARKETING", "MANAGER"],
      photo: Grace,
    },
    {
      name: "Jessica",
      positions: ["CORPORATE PARTNERSHIP", "MANAGER"],
      photo: Jessica,
    },
    {
      name: "Erika",
      positions: ["ACCESS USER", "EXPERIENCE DESIGNER"],
      photo: Erika,
    },
    {
      name: "Hussain",
      positions: ["GRAPHIC", "DESIGNER"],
      photo: Hussain,
    },
    {
      name: "Pavel",
      positions: ["LEAD FRONT END", "DEVELOPER"],
      photo: Pavel,
    },
    {
      name: "Kostya",
      positions: ["SENIOR SYSTEMS", "ARCHITECT"],
      photo: Kostya,
    },
    {
      name: "Stanislav",
      positions: ["LEAD FRONT END", "DEVELOPER"],
      photo: Stas,
    },
    {
      name: "Vitaly",
      positions: ["LEAD USER EXPERIENCE", "DESIGNER"],
      photo: Vitaly,
    },
    {
      name: "Adam",
      positions: ["LEAD USER EXPERIENCE", "DESIGNER"],
      photo: Adam,
    },
    {
      name: "Nika",
      positions: ["USER INTERFACE", "DESIGNER"],
      photo: Nika,
    },
    {
      name: "Oleg",
      positions: ["LEAD FRONT END", "DEVELOPER"],
      photo: Oleg,
    },
    {
      name: "Vlad",
      positions: ["LEAD BACK END", "DEVELOPER"],
      photo: Vlad,
    },
    {
      name: "Alexander",
      positions: ["FRONT END", "DEVELOPER"],
      photo: Alex1,
    },
    {
      name: "Yevgen",
      positions: ["SENIOR BACKEND", "DEVELOPER"],
      photo: Yevgen,
    },
    {
      name: "Roman",
      positions: ["SENIOR FRONT END", "DEVELOPER"],
      photo: RomanLitvin,
    },
    {
      name: "Oleksander",
      positions: ["FRONT END", "DEVELOPER"],
      photo: Oleksander,
    },
    {
      name: "Anastasia",
      positions: ["PHOTOGRAPHER"],
      photo: Anastasia,
    },
    {
      name: "Roman",
      positions: ["BACK END", "DEVELOPER"],
      photo: RomanBE,
    },
    {
      name: "",
      positions: [""],
      photo: "",
    },
    {
      name: "",
      positions: [""],
      photo: "",
    },
  ],
};
